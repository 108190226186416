<template>
    <DownLoadLog
        :LogDataArr="LogArr"
        :Describe="langArr['html.DownLoadLog.Describe']"
        @downloadFile = 'downloadFile'
    />
</template>

<script setup>
import DownLoadLog from "../../components/DownLoadLog";
import {ref} from "vue";
import api from "../../api/axiosConfig";
import common from "../../utils/common";
import {fromBase64} from "js-base64";
import lang from '../../lang/index';
const langArr = ref(lang.getLang('user','BatchRegUserLog'));

let LogArr = ref([]);
api.post('User.Register/getLog').then(res=>{
    if (!common.checkRes(res,true)){
        return;
    }
    let arr = res.data.data ?? [];
    let i = 1;
    for (let arrKey in arr) {
        LogArr.value.push({
            id:i++,
            file:arr[arrKey].replace(/.txt/g,'')
        });
    }
});

const downloadFile = (downloadFile) => {
    api.post('User.Register/getFile',{file:downloadFile}).then(res=>{
        if (!common.checkRes(res,true)){
            return;
        }
        let data = res.data.data;
        let str = fromBase64(data);
        common.downLoad(str,downloadFile + `.txt`);
    })
}
</script>

<style scoped>

</style>